import { Amplify } from 'aws-amplify';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../constants/routes.constants';
import ApplicationLayout from '../modules/application-layout';
import UnderDevelopment from '../shared/under-development';
import { RequireAuth, ValidateAuth } from '../shared/require-auth';
import Login from '../modules/auth/login';
import TrainingLayout from '../modules/training-flow/training-layout';

const ApplicationRouter = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.home}
        key='component_home'
        element={
          <RequireAuth>
            <ApplicationLayout>{<TrainingLayout />}</ApplicationLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.training}
        key='component_training'
        element={
          <RequireAuth>
            <ApplicationLayout>{<TrainingLayout />}</ApplicationLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.settings}
        key='component_underdevelopment1'
        element={
          <RequireAuth>
            <ApplicationLayout>{<UnderDevelopment />}</ApplicationLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.students}
        key='component_underdevelopment2'
        element={
          <RequireAuth>
            <ApplicationLayout>{<UnderDevelopment />}</ApplicationLayout>
          </RequireAuth>
        }
      />
      <Route
        path={ROUTES.login}
        key='component_login'
        element={<ValidateAuth>{<Login />}</ValidateAuth>}
      />
      <Route
        path={ROUTES.all}
        key='component_all'
        element={<ValidateAuth>{<Login />}</ValidateAuth>}
      />
    </Routes>
  );
};
export default ApplicationRouter;
