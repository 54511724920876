import { WarningCircle } from '@phosphor-icons/react';
import React from 'react';
import { CONNECTIVITY_ERROR_TEXT } from '../constants/training.constants';

interface NoNetworkOverlayProps {
  open: boolean;
}

export function NoNetworkOverlay({ open }: NoNetworkOverlayProps) {
  return (
    <div
      className={`fixed inset-0 flex flex-col
       justify-center items-center transition-colors h-full w-full gap-4 ${
         open ? 'visible bg-black/80' : 'invisible'
       }`}
    >
      <WarningCircle size={60} className=' text-yellow-600' />
      <p className=' text-slate-300 font-bold text-lg'>
        {CONNECTIVITY_ERROR_TEXT.CONNECTIVITY_ERROR}
      </p>
    </div>
  );
}
