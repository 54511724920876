import React from 'react';

interface MetricsCardProps {
  number: string | number;
  icon: JSX.Element;
  text: string;
}

function MetricsCard({ number, icon, text }: MetricsCardProps) {
  return (
    <div
      className='metric-card rounded-lg flex flex-col h-[66px] xl:h-[75px] border-slate-700  border w-full bg-slate-900'
      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      <div className='flex px-3 py-2 xl:px-4 xl:py-3'>
        <div className='mt-1 flex items-center justify-center bg-slate-800 w-[25px] h-[25px] xl:w-[30px] xl:h-[30px] rounded-lg text-slate-300 shadow mr-5 '>
          {icon}
        </div>
        <div className=''>
          <div className=' font-bold text-[23px] text-slate-50'>{number}</div>
          <div className='leading-4 text-[8px] lg:text-[12px] text-slate-400'>
            <p className='line-clamp-1'>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MetricsCard;
