import React from 'react';
import { useTrip } from '../context/trip-context';
import { ArrowRight } from '@phosphor-icons/react';
import { BUTTON_MESSAGES } from '../constants/training.constants';

export function ContinueWithTrip() {
  const trip = useTrip();
  return (
    <div className='continue-with-trip-button flex justify-center py-3'>
      <button
        className='flex items-center justify-center hover:bg-slate-100 bg-slate-50  text-black py-2 px-4 rounded'
        onClick={() => {
          if (trip) {
            trip.continueWithTrip();
          }
        }}
      >
        <div className='pr-2'>{BUTTON_MESSAGES.CONTINUE_WITH_TRIP}</div>
        <div>
          <ArrowRight size={24} color='#000000' />
        </div>
      </button>
    </div>
  );
}
