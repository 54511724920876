import { GraphQLSubscription } from '@aws-amplify/api';
import { Student, IdentificationBadge, Bus } from '@phosphor-icons/react';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import {
  APP_CONSTANTS,
  APP_STATUS,
  MATCH_TYPE,
  METRICS_CARD_MESSAGES,
} from '../../constants/training.constants';
import { useAuth } from '../../context/auth-context';
import { useTrip } from '../../context/trip-context';
import { OnUpdateTripEntrySubscription } from '../../graphql/API';
import { onUpdateTripEntry } from '../../graphql/subscription';
import { ConfirmationDialogBox } from '../../shared/confirmation-dialogbox';
import { EmptyState } from '../../shared/empty-state';
import { ExitAndCollapse } from '../../shared/exit-and-collapse-button';
import FullMatch from '../../shared/full-match';
import MetricsCard from '../../shared/metrics-card';
import PartialMatch from '../../shared/partial-match';
import { StudentList } from '../../shared/student-list';
import { endTrip } from '../../utils/Api-calls/end-trip';
import { fetchActiveTrips } from '../../utils/Api-calls/fetch-active-trips';
import { startTrip } from '../../utils/Api-calls/start-trip';
import { PastState } from '../../shared/past-state';
import NewTimeline from './new-timeline';
import { fetchTimeline } from '../../utils/Api-calls/timeline';
import useInternetConnection from '../../hooks/useInternetConnection';
import { NoNetworkOverlay } from '../../shared/noNetworkOverlay';

let timer: NodeJS.Timeout | null = null;

function TrainingLayout() {
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [selectedData, setSelectedData] = useState('');
  const [timelineData, setTimelineData] = useState<any>(null);

  const [isEndTripModalVisible, setIsEndTripModalVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const trip = useTrip();
  const auth = useAuth();
  const isConnected = useInternetConnection();
  const [selectedEpoch, setSelectedEpoch] = useState('');

  // Init function to load app data.
  const init = async () => {
    let activeTripId = null;
    // Loading the trip data if it is not present
    const response = await fetchActiveTrips();
    if (!response?.activeTrips.length) {
      // Starting new trip if there is no active trip found in the system.
      const newTripData = await startTrip();
      activeTripId = newTripData.tripId;
    } else {
      activeTripId = response.activeTrips[0].tripId;
    }
    // Updating the trip information in the store.
    await trip?.updateTripData(activeTripId);
    fetchTimelineData(activeTripId);
    setShowLoader(false);
    // Calling Timeline API on Init
    return;
  };

  // Call Timeline API when the App Status Changes
  useEffect(() => {
    if (trip?.tripData?.tripId && trip?.appStatus.current === APP_STATUS.INITIAL) {
      fetchTimelineData(trip.tripData?.tripId);
    }
  }, [trip?.appStatus.current]);

  //Function to Call Timeline API
  const fetchTimelineData = async (tripId: string) => {
    const data = await fetchTimeline(tripId);
    setTimelineData(data);
  };

  const changeAppStatus = (appStatus: APP_STATUS) => {
    if (trip) {
      trip.appStatus.current = appStatus;
    }
  };

  // To process the face recognition event from the web socket.
  const processFaceRecognitionEvent = (data: OnUpdateTripEntrySubscription) => {
    const payload = data.OnUpdateTripEntry;
    console.log('RENDER_  _SUBSCRIPTION_   ,   _APP_STATUS_', payload, trip?.appStatus.current);
    // Calling Timeline API on Subscription Message Type
    if (trip?.matchData?.messageType === 'FACE_DATA') {
      fetchTimelineData(trip?.tripData?.tripId as string);
      return;
    }
    // Allowing the next screen if the application is not paused for manual selection
    if (trip?.appStatus.current === APP_STATUS.INITIAL) {
      // Assigning the new face detection response to the store variable. This will trigger downstream actions
      trip?.setMatchData(payload);
      // Assigning the screen based on the match type
      switch (payload?.status) {
        case MATCH_TYPE.PARTIAL_MATCH:
        case MATCH_TYPE.NO_MATCH:
          if (timer) clearTimeout(timer);
          // Freezing the application for manual selection.
          changeAppStatus(APP_STATUS.PAUSED);
          break;
        case MATCH_TYPE.FULL_MATCH:
          changeAppStatus(APP_STATUS.IN_PROGRESS);
          // Fetching the updated trip information
          trip?.updateTripData(payload.tripEntry.tripId);
          // Resetting the application after 10s of idle time.
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            changeAppStatus(APP_STATUS.INITIAL);
          }, APP_CONSTANTS.STANDARD_WAIT_TIME);
          break;
        default:
          changeAppStatus(APP_STATUS.INITIAL);
          break;
      }
    }
  };

  // This hook will load data for the initial load
  useEffect(() => {
    // Initializing the app
    init();

    const handleBeforeUnload = async (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };

    // Subscription to load data from the Camera Device.
    const sub = API.graphql<GraphQLSubscription<OnUpdateTripEntrySubscription>>(
      graphqlOperation(onUpdateTripEntry),
    ).subscribe({
      next: (data) => {
        const payload = data.value.data as OnUpdateTripEntrySubscription;
        // Processing the captured Camera Event.
        processFaceRecognitionEvent(payload);
      },
      error: (error) => {
        return null;
      },
    });
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      sub.unsubscribe();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const studentMetrics = [
    {
      icon: <Student />,
      number: trip?.tripData?.tripSummary.totalStudentsInTheTrip,
      text: METRICS_CARD_MESSAGES.TOTAL_STUDENTS,
    },
    {
      icon: <IdentificationBadge />,
      number: trip?.tripData?.tripSummary.totalStudentsBoardedTheTrip,
      text: METRICS_CARD_MESSAGES.PICKED_STUDENTS,
    },
    {
      icon: <Bus />,
      number: trip?.tripData?.tripSummary.totalNoShows,
      text: METRICS_CARD_MESSAGES.NO_SHOWS,
    },
  ];

  const renderComponent = () => {
    if (trip?.matchData?.status === MATCH_TYPE.FULL_MATCH) {
      return (
        <div className='flex justify-center items-center h-full'>
          <FullMatch
            onBtnClk={() => {
              // Resetting the app to initial status if driver wants to continue the trip
              // trip?.setAppStatus(APP_STATUS.INITIAL);
            }}
            capturedImageUrl={trip?.matchData?.imageSrc as string}
            actualImageUrl={trip?.matchData?.student.image as string}
            name={trip?.matchData?.student.studentName as string}
          />
        </div>
      );
    } else if (
      trip?.matchData?.status == MATCH_TYPE.PARTIAL_MATCH ||
      trip?.matchData?.status == MATCH_TYPE.NO_MATCH
    ) {
      return (
        <div>
          <PartialMatch
            showLeftPanel={showLeftPanel}
            matchType={trip?.matchData?.status}
            onContinueBtnClk={() => {
              // Resetting the app to initial status if driver wants to continue the trip
              // trip?.setAppStatus(APP_STATUS.INITIAL);
            }}
            similarFaces={trip?.matchData.similarFaces}
            actualImage={trip?.matchData.imageSrc}
          />
        </div>
      );
    }
  };

  return (
    <>
      {showLoader ? (
        <div className='flex space-x-2 justify-center items-center bg-slate-950 h-screen'>
          <div className='h-4 w-4 bg-slate-700 rounded-full animate-bounce [animation-delay:-0.6s]'></div>
          <div className='h-4 w-4 bg-slate-700 rounded-full animate-bounce [animation-delay:-0.30s]'></div>
          <div className='h-4 w-4 bg-slate-700 rounded-full animate-bounce'></div>
        </div>
      ) : (
        <>
          {/* Background */}
          <div className='dashboard-container flex bg-slate-950'>
            {showLeftPanel && (
              <div className='metrics-list-container relative w-[25%] h-screen p-4'>
                <div>
                  <ExitAndCollapse
                    onExitButtonPress={() => setIsEndTripModalVisible(true)}
                    style='justify-between'
                    onCollapsePress={() => {
                      setShowLeftPanel(!showLeftPanel);
                    }}
                  />
                </div>
                <div className='metrics-container '>
                  <div className='mt-[5px]'>
                    {studentMetrics.map((studentMetric, index) => (
                      <div key={index} className='mt-[6px]'>
                        <MetricsCard
                          key={index}
                          icon={studentMetric.icon}
                          number={studentMetric.number ? studentMetric.number : 0}
                          text={studentMetric.text}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className='my-4'>
                  <StudentList />
                </div>
              </div>
            )}
            <div
              className={`selection-screen-container ${
                showLeftPanel ? `relative w-[75%] h-screen py-4 pr-4` : `w-full h-screen p-4`
              }`}
            >
              <div className='rounded-md bg-slate-900 h-full'>
                <div
                  className='h-full flex flex-col justify-around'
                  // style={{ overflowY: 'auto'}}
                >
                  {!showLeftPanel && (
                    <div className='exit-expand w-[10%] px-[7px] pt-[16px]'>
                      <ExitAndCollapse
                        style=''
                        onCollapsePress={() => {
                          setShowLeftPanel(!showLeftPanel);
                        }}
                        onExitButtonPress={() => setIsEndTripModalVisible(true)}
                      />
                    </div>
                  )}
                  {/* Empty State */}
                  {trip?.matchData && trip.appStatus.current !== APP_STATUS.INITIAL ? (
                    // <div className='flex justify-center'>
                    <>{renderComponent()}</>
                  ) : // </div>
                  !selectedEvent ? (
                    <div className='flex justify-center items-center h-full'>
                      <EmptyState />
                    </div>
                  ) : (
                    <div className='flex justify-center items-center h-full'>
                      <PastState selectedData={selectedData} />
                    </div>
                  )}
                  {trip?.appStatus.current === APP_STATUS.INITIAL ||
                  trip?.appStatus.current === APP_STATUS.IN_PROGRESS ? (
                    <>
                      <div className='px-2 flex justify-center flex-col'>
                        <div>
                          <div className='flex justify-center'>
                            <p className='text-white font-bold text-lg'>{selectedEpoch}</p>
                          </div>
                        </div>
                        <NewTimeline
                          timelineData={timelineData}
                          setSelectedEpoch={setSelectedEpoch}
                          setSelectedData={setSelectedData}
                          setSelectedEvent={setSelectedEvent}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ConfirmationDialogBox
            open={isEndTripModalVisible}
            onClose={() => {
              setIsEndTripModalVisible(false);
            }}
            modalTitle={'Are you sure to end route?'}
            modalContent={
              'Once the route is ended, you won’t be able to update the this days route information anymore.'
            }
            onSuccessButtonPress={async () => {
              await endTrip();
              await auth?.logout();
              trip?.reset();
            }}
            onCancelButtonText={'Cancel'}
            onSuccessButtonText={'Confirm & End Route'}
          />
          <NoNetworkOverlay open={!isConnected}></NoNetworkOverlay>
        </>
      )}
    </>
  );
}

export default TrainingLayout;
