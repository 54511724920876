import React from 'react';
import { studentCardColumns } from '../constants/dashboard.constants';
import { useTrip } from '../context/trip-context';

export function StudentList() {
  const trip = useTrip();
  const getStatusColorClass = (status: string) => {
    switch (status) {
      case 'Ongoing':
        return 'bg-blue-50 text-blue-600 ';
      case 'Same or Different':
        return 'bg-blue-50 text-blue-600 ';
      case 'Identify':
        return 'bg-purple-900 border-purple-900 text-purple-200';
      case 'Picked Up':
        return 'bg-emerald-900 border-emerald-900 text-emerald-200';
      default:
        return '';
    }
  };

  const getStatusPillDot = (status: string) => {
    switch (status) {
      case 'Ongoing':
        return 'bg-blue-500';
      case 'Picked Up':
        return 'bg-emerald-200';
      case 'Identify':
        return 'bg-purple-200';
      case 'Same or Different':
        return 'bg-blue-500';
      default:
        return '';
    }
  };

  return (
    <>
      <div
        className='students-list relative rounded-lg border-slate-700 border overflow-hidden bg-slate-900 max-h-[100%] h-[50vh] xl:h-[60vh]'
        style={{ overflowY: 'auto' }}
      >
        <div className='p-3'>
          <table className='w-full divide-y divide-slate-700 bg-slate-900'>
            <thead className='sticky top-0 bg-slate-900 scroll-pt-3'>
              <tr>
                {studentCardColumns.map((columnName, index) => (
                  <th
                    key={index}
                    className={`leading-2 py-2 lg:text-[14px] text-[12px] w-[50%] text-left
                     text-slate-300 font-medium tracking-wider font-inter`}
                  >
                    {columnName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='bg-slate-900 divide-y divide-slate-700'>
              {trip?.tripData?.tripEntries ? (
                trip?.tripData?.tripEntries.map((user, index) => (
                  <tr key={index}>
                    <td className='py-2 pr-3 leading-3 whitespace-nowrap text-left font-medium text-slate-300'>
                      {user.student_name}
                    </td>
                    <td className='py-2 pr-2 leading-3 whitespace-nowrap text-left'>
                      <div
                        className={`inline-block rounded-full py-1 px-3 text-xs border ${getStatusColorClass(
                          user.status == 'NOT_ONBOARDED' ? 'Identify' : 'Picked Up',
                        )}`}
                      >
                        <span
                          className={`w-2 h-2 rounded-full mr-2 inline-block ${getStatusPillDot(
                            user.status == 'NOT_ONBOARDED' ? 'Identify' : 'Picked Up',
                          )}`}
                        ></span>
                        {user.status == 'NOT_ONBOARDED' ? 'Identify' : 'Picked Up'}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
