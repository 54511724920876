export const onUpdateTripEntry = `subscription OnUpdateTripEntry {
    OnUpdateTripEntry {
      batchId
      gif
      messageType
      imageSrc
      similarFaces {
        id
        image
        studentName
      }
      status
      student {
        id
        image
        studentName
      }
      timestamp
      tripEntry {
        status
        studentId
        timeStampMs
        timestamp
        tripId
      }
      tripSummary {
        totalNoShows
        totalStudentsBoardedTheTrip
        totalStudentsInTheTrip
      }
    }
  }`;
