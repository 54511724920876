import { Hammer } from '@phosphor-icons/react';
import React from 'react';

function UnderDevelopment() {
  return (
    <div className='rounded-lg flex flex-col border border-slate-200 p-6 h-[125px] w-full'>
      <div className='flex justify-center align-middle'>
        <Hammer size={32} color='#DA291C' />
      </div>
      <div className='flex justify-center align-middle pt-3'>
        <h1 className='text-lg'>Under Development</h1>
      </div>
    </div>
  );
}

export default UnderDevelopment;
