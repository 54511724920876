export const awsExports = {
  Auth: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_GW_END_POINT_NAME,
        endpoint: process.env.REACT_APP_API_GW_END_POINT,
      },
    ],
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPH_QL_END_POINT,
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: process.env.REACT_APP_GRAPH_QL_AUTH_KEY,
};
