import { API } from 'aws-amplify';

export async function manualEntry(
  tripId: string,
  studentId: string,
  batchId: string,
  matchType: string,
) {
  const apiName = process.env.REACT_APP_API_GW_END_POINT_NAME as string;
  const path = '/v1/trip/entry';
  const myInit = {
    body: {
      tripId,
      batchId,
      matchType,
      studentId,
      status: 'ONBOARDED',
    }, // replace this with attributes you need
    headers: {}, // OPTIONAL
  };

  return await API.put(apiName, path, myInit)
    .then((response) => {
      // Add your code here
      return response;
    })
    .catch((error) => {
      console.log(error.response);
    });
}
