import React, { useRef } from 'react';
import { formatTimestampToTime } from '../../utils/common.utils';
import { useDraggable } from 'react-use-draggable-scroll';

function NewTimeline(props: {
  setSelectedEpoch: React.Dispatch<React.SetStateAction<string>>;
  setSelectedEvent: React.Dispatch<React.SetStateAction<any>>;
  setSelectedData: React.Dispatch<React.SetStateAction<string>>;
  timelineData: any;
}) {
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  // Logic for Selecting Frames
  const selectFrame = (frame: any) => {
    if (frame !== 'NO_EVENT') {
      props.setSelectedEvent(frame);
    } else props.setSelectedEvent(false);
  };

  // Logic for Timestamp selection
  const handleDivClick = (epoch: string) => {
    props.setSelectedEpoch(epoch);
  };

  function getBatchStyle(status: string) {
    switch (status) {
      case 'FULL_MATCH':
        return 'bg-emerald-800 border-emerald-900 border-4 rounded-lg';
      case 'PARTIAL_MATCH':
        return 'bg-purple-800 border-purple-900 border-4 rounded-lg ';
      case 'NO_MATCH':
        return 'bg-red-800 border-red-900 border-4 rounded-lg ';
      default:
        return '';
    }
  }

  return (
    <>
      <div
        className='flex items-center overflow-hidden py-2 px-1'
        style={{ overflowX: 'auto' }}
        {...events}
        ref={ref}
      >
        {props.timelineData?.map((data: any) => (
          <div
            key={`data-${data.batchId}`}
            className={`timeline flex flex-row items-center mx-1 ${getBatchStyle(
              data.faceMatches[0]?.matchType,
            )}`}
            ref={timelineRef}
          >
            {(data.frames.length < 15
              ? Array(15 - data.frames.length)
                  .fill(data.frames[data.frames.length - 1])
                  .concat(data.frames)
              : data.frames
            ).map((frame: any, index: any) => (
              <div
                key={`frame-${index}-${frame}`}
                className={`flex flex-col items-center`}
                onClick={() => {
                  console.log(data.gifUrl);
                  props.setSelectedData(data.gifUrl);
                  selectFrame(data.faceMatches[0]?.matchType);
                }}
              >
                <div
                  onClick={() => {
                    handleDivClick(formatTimestampToTime(frame.timestamp));
                  }}
                  className={`bg-slate-50 mx-4 rounded-md ${
                    index % 4 === 0
                      ? 'h-10 w-big-line my-4 bg-slate-300 hover:bg-slate-200'
                      : 'h-4 w-small-line my-7 bg-slate-400 hover.bg-slate-300'
                  }`}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

export default NewTimeline;
