export function getDisplayTime() {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
}

export function formatTimestampToTime(timestamp: number) {
  const timestampInMilliseconds = timestamp * 1000;
  const date = new Date(timestampInMilliseconds);
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });
  return formattedTime;
}

export function isPreSignedUrl(url: string) {
  if (url.includes('https://')) {
    console.log('TRUE');
    return true;
  }
  console.log('FALSE');
  return false;
}
