import { SidebarSimple } from '@phosphor-icons/react';
import React from 'react';
import { BUTTON_MESSAGES } from '../constants/training.constants';
import { ExitAndCollapseProps } from '../types/ExitAndCollapse';

export function ExitAndCollapse({
  style,
  onCollapsePress,
  onExitButtonPress,
}: ExitAndCollapseProps) {
  return (
    <div className={`flex gap-1 ${style}`}>
      <button
        onClick={onExitButtonPress}
        className='relative bg-slate-800 rounded-[72px] py-[6px] w-[70px] md:w-[70px] text-white text-[12px]'
      >
        <span>&#10005; </span>
        <span className=''>{BUTTON_MESSAGES.EXIT_BUTTON_TEXT}</span>
      </button>
      <button
        onClick={onCollapsePress}
        className='relative bg-slate-800 rounded-[72px] px-[12px] w-[38px] py-[6px] text-white text-[16px] xl:text-[24px] flex justify-center items-center'
      >
        <SidebarSimple />
      </button>
    </div>
  );
}
