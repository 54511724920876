import React, { useState } from 'react';
import { BUTTON_MESSAGES } from '../constants/training.constants';

interface ConfirmationDialogBoxProps {
  open: boolean;
  onClose: () => void;
  onSuccessButtonPress: () => void;
  modalContent: string;
  modalTitle: string;
  onSuccessButtonText: string;
  onCancelButtonText: string;
}

export function ConfirmationDialogBox({
  open,
  onClose,
  modalContent,
  modalTitle,
  onSuccessButtonPress,
  onCancelButtonText,
  onSuccessButtonText,
}: ConfirmationDialogBoxProps) {
  const [showLoader, setShowLoader] = useState(false);

  return (
    <div
      onClick={onClose}
      className={`fixed inset-0 flex justify-center items-center transition-colors ${
        open ? 'visible bg-black/80' : 'invisible'
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={` bg-slate-900 rounded-lg shadow transition-all  ${
          open ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
        } p-5 w-[520px]`}
      >
        <div className={'flex justify-start flex-col'}>
          <p className={'mb-2 font-semibold text-[17px] leading-5 text-white'}>{modalTitle}</p>
          <p className={'font-normal text-[14px] leading-5 text-slate-300'}>{modalContent}</p>
        </div>
        <div className={'flex justify-end mt-7'}>
          <button
            className={' py-[10px] px-[24px] rounded-[4px] text-white items-center mr-2'}
            onClick={onClose}
          >
            {onCancelButtonText}
          </button>
          <button
            className={' bg-red-600 py-[10px] px-[24px] rounded-[4px] text-white items-center'}
            onClick={() => {
              onSuccessButtonPress();
              setShowLoader(true);
            }}
          >
            {showLoader ? `${BUTTON_MESSAGES.LOADING}...` : `${onSuccessButtonText} `}
            {!showLoader && <span>&rarr;</span>}
          </button>
        </div>
      </div>
    </div>
  );
}
