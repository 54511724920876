import { API } from 'aws-amplify';

export async function endTrip() {
  const apiName = process.env.REACT_APP_API_GW_END_POINT_NAME as string;
  const path = '/v1/trip/endall';
  const myInit = {
    body: {}, // replace this with attributes you need
    headers: {}, // OPTIONAL
  };

  return await API.del(apiName, path, myInit);
}
