import { API } from 'aws-amplify';

export async function startTrip() {
  const apiName = process.env.REACT_APP_API_GW_END_POINT_NAME as string;
  const path = '/v1/trip/start';
  const myInit = {
    body: {}, // replace this with attributes you need
    headers: {}, // OPTIONAL
  };

  // return await API.post(apiName, path, myInit);

  return await API.post(apiName, path, myInit)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return null;
    });
}
