import React, { useState } from 'react';
import { StudentType } from '../graphql/API';
import ImageCard from './image-card';

type ThreeCardProps = {
  similarFaces: [StudentType];
};

export default function ThreeCard({ similarFaces }: ThreeCardProps) {
  const [selectedValue, setSelectedValue] = useState('');
  return (
    <div>
      {similarFaces.map((item) => (
        <div
          key={item.id}
          className='three-card-component flex flex-col justify-center items-center xl:p-2 '
        >
          <ImageCard
            isSelected={selectedValue === item.id}
            setSelectedValue={setSelectedValue}
            studentType={item}
          />
        </div>
      ))}
    </div>
  );
}
