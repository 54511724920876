import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { StudentType } from '../graphql/API';
import ThreeCard from './three-card';
import { MATCH_TYPE, PARTIAL_MATCH_CONSTANTS } from '../constants/training.constants';
import { ImageComponent } from './full-match';
import HorizontalScroll from './horizontal-scroll';
import { ContinueWithTrip } from './continue-with-trip';

type PartialMatchProps = {
  similarFaces: [StudentType];
  actualImage: string;
  onContinueBtnClk: () => void;
  matchType: 'PARTIAL_MATCH' | 'NO_MATCH';
  showLeftPanel: boolean;
};

export default function PartialMatch({
  similarFaces,
  actualImage,
  showLeftPanel,
  matchType,
}: PartialMatchProps) {
  return (
    <div
      className={`partial-match-container relative flex items-center justify-center w-full h-[${
        !showLeftPanel ? '80' : '100'
      }vh] rounded-md xl:gap-14 gap-10`}
    >
      {/* left side */}
      <div className='identified-image-container'>
        {/* text */}
        <div className='header'>
          <p className='text-white/70 xl:text-[14px] text-[10px] font-bold mb-2 flex justify-center'>
            {PARTIAL_MATCH_CONSTANTS.MANUAL_INPUT_NEEDED}
          </p>
          <p className='text-white xl:text-[29px] text-[24px] font-bold mb-[26px] whitespace-nowrap text-center'>
            {matchType === MATCH_TYPE.NO_MATCH
              ? PARTIAL_MATCH_CONSTANTS.NO_MATCH_TYPE
              : PARTIAL_MATCH_CONSTANTS.PARTIAL_MATCH_TYPE}
          </p>
        </div>
        {/* image */}
        <div className='identified-image flex justify-center'>
          {/* <ImageComponent url={`data:image/png;base64,${actualImage}`} /> */}
          <ImageComponent url={actualImage} />
        </div>
        <ContinueWithTrip />
      </div>
      {/* Arrow */}
      <div className='text-slate-500'>
        <ArrowRight size={72} />
      </div>
      {/* right side */}
      <div
        className={`${
          matchType === MATCH_TYPE.NO_MATCH
            ? `h-[90vh] overflow-y-scroll scroll-smooth`
            : `h-[90vh]  flex flex-col justify-center items-center`
        }`}
      >
        {matchType === MATCH_TYPE.NO_MATCH ? (
          <HorizontalScroll similarFaces={similarFaces} />
        ) : (
          <ThreeCard similarFaces={similarFaces} />
        )}
      </div>
    </div>
  );
}

//BUTTON COMPONENT FOR FUTURE USE:
//   /* <button onClick={onButtonClick} className={'bg-white rounded-[3px] p-2 w-[60%] tablet:w-[70%] text-[12px] tablet:text-[14px]'}>
// Continue with Trip &rarr;
// </button> */
