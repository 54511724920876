import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { USER_DATA } from '../../utils/localstorage.utils';
import logo from '../../assets/brand_logo.svg';
import testimonial from '../../assets/login_testimonial.png';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { useAuth } from '../../context/auth-context';
import useInternetConnection from '../../hooks/useInternetConnection';
import { LOGIN_FORM_TEXT } from '../../constants/login.constants';
import { LoginAlert } from '../../shared/loginAlert';

function Login() {
  const { register, handleSubmit } = useForm();
  const auth = useAuth();
  const isConnected = useInternetConnection();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [isIncorrectCreds, setIsIncorrectCreds] = useState(false);

  const changePasswordVisibility = () => {
    setShowPassword(!isShowPassword);
  };

  const onSubmit = async (event: any) => {
    console.log('pressed');
    setIsLoading(true);
    try {
      const user = await auth?.login(event?.email, event?.password);
      USER_DATA.set(user);
    } catch (error) {
      console.log(error);
      if (
        (error as { name: string }).name === 'UserNotFoundException' ||
        (error as { name: string }).name === 'NotAuthorizedException'
      ) {
        setIsIncorrectCreds(true);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className='w-full h-[100vh] flex flex-row '>
      <div className='h-full w-full tablet:w-6/12 flex justify-center '>
        <form id='login' onSubmit={handleSubmit(onSubmit)} className='h-full'>
          <div className='flex flex-col items-center justify-center h-full align-middle'>
            <div className=' shadow rounded border-solid border border-slate-200 w-[425px] min-w-[300px] h-[482px] px-[29px] py-[36px]'>
              <div>
                <img src={logo} alt={'brand_logo'} className='mb-[29px]'></img>
              </div>
              <p className=' text-2xl mb-[10px] font-bold text-left text-slate-950'>
                {LOGIN_FORM_TEXT.LOGIN_TO_YOUR_ACCOUNT}
              </p>
              <p className='mb-[29px] text-slate-500 text-sm leading-4 font-normal'>
                {LOGIN_FORM_TEXT.ENTER_ACC_DETAILS}
              </p>
              <div className='text-left mb-[29px]'>
                <label className='leading-4 text-sm text-slate-500 font-semibold mb-[8px]'>
                  {LOGIN_FORM_TEXT.EMAIL}
                </label>
                <input
                  aria-label='enter email address'
                  onFocus={() => setIsIncorrectCreds(false)}
                  placeholder='Enter your email address'
                  className='border border-slate-300 rounded-md p-[10px] focus:outline-none text-xs font-medium leading-none text-black w-full '
                  {...register('email')}
                />
              </div>
              <div className='text-left mb-[29px]'>
                <label className='leading-4 text-sm text-slate-500 font-semibold mb-[8px]'>
                  {LOGIN_FORM_TEXT.PASSWORD}
                </label>
                <div className='relative flex items-center justify-center'>
                  <input
                    aria-label='enter Password'
                    onFocus={() => setIsIncorrectCreds(false)}
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder='Enter your password here'
                    className='border border-slate-300 rounded-md p-[10px] focus:outline-none text-xs font-medium leading-none text-black w-full '
                    {...register('password')}
                  />
                  <div className='absolute right-4' onClick={changePasswordVisibility}>
                    {isShowPassword ? <Eye size={20} /> : <EyeSlash size={20} />}
                  </div>
                </div>
              </div>
              <div className=''>
                <button
                  type='submit'
                  aria-label='Sign In'
                  className={`bg-red-600 disabled:bg-red-600/70 text-sm font-medium leading-5 text-slate-50 rounded-[4px] py-2.5 px-6 w-full`}
                  disabled={isLoading || !isConnected}
                >
                  {isConnected
                    ? isLoading
                      ? LOGIN_FORM_TEXT.LOADING
                      : LOGIN_FORM_TEXT.SIGN_IN
                    : LOGIN_FORM_TEXT.NO_INTERNET}
                </button>
              </div>
              {isIncorrectCreds && <LoginAlert />}
            </div>
          </div>
        </form>
      </div>
      <div className='mx-5 mr-5 h-full align-middle hidden tablet:flex p-10'>
        <img src={testimonial} alt={'testimonial'}></img>
      </div>
    </div>
  );
}

export default Login;
