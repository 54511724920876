export enum LOGIN_FORM_TEXT {
  EMAIL = 'Email',
  PASSWORD = 'Password',
  LOADING = 'Loading ...',
  SIGN_IN = 'Sign In',
  LOGIN_TO_YOUR_ACCOUNT = 'Login to your account',
  ENTER_ACC_DETAILS = 'Enter your account details to continue.',
  NO_INTERNET = 'No Internet Connection...!',
  INCORRECT_CREDS = `You've entered an incorrect email address or password.`,
  SIGN_IN_ERROR = 'Sign In Error',
}
