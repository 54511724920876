import React, { useState } from 'react';
import { StudentType } from '../graphql/API';
import ImageCard from './image-card';

type HorizontalScrollProps = {
  similarFaces: [StudentType];
};

export default function HorizontalScroll({ similarFaces }: HorizontalScrollProps) {
  const [selectedValue, setSelectedValue] = useState('');

  return (
    <div>
      {similarFaces.map((item) => (
        <div
          key={item.id}
          className='flex justify-center flex-col items-center tablet:p-3 tablet:gap-3'
        >
          <ImageCard
            isSelected={selectedValue === item.id}
            setSelectedValue={setSelectedValue}
            studentType={item}
          />
        </div>
      ))}
    </div>
  );
}
