import React from 'react';

function ApplicationLayout({ children }: any) {
  return (
    <>
      <div className='application-layout'>{children}</div>
    </>
  );
}

export default ApplicationLayout;
