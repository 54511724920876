import React from 'react';
import { EMPTY_STATE_TEXT } from '../constants/training.constants';
import EmptyStateIamge from '../assets/empty-state.svg';

export function EmptyState() {
  return (
    <div className='h-[60vh] lg:h-[65vh] flex flex-col justify-center items-center gap-20'>
      <img src={EmptyStateIamge} alt={'....'} className='h-[40vh] lg:h-[50vh]' />
      <div className='flex flex-row gap-4'>
        <p className='text-slate-300 font-bold xl:text-3xl text-2xl'>
          {EMPTY_STATE_TEXT.EMPTY_STATE_TEXT}
        </p>
      </div>
    </div>
  );
}
