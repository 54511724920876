export const USER_DATA = {
  set: (user: any) => {
    localStorage.setItem('first-student-user', JSON.stringify(user));
  },
  remove: () => {
    if (localStorage.getItem('first-student-user')) {
      localStorage.removeItem('first-student-user');
    }
  },
  get: () => localStorage.getItem('first-student-user'),
};
