import React from 'react';
import { LOGIN_FORM_TEXT } from '../constants/login.constants';

export function LoginAlert() {
  return (
    <div
      className={
        'bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3 mt-2 rounded-md'
      }
      role='alert'
    >
      <p className='font-semibold'>{LOGIN_FORM_TEXT.SIGN_IN_ERROR}</p>
      <p className='text-[12px]'>{LOGIN_FORM_TEXT.INCORRECT_CREDS}</p>
    </div>
  );
}
