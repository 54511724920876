import { API } from 'aws-amplify';
import { TripSummary } from '../../graphql/API';

type TripData = {
  tripId: string;
  timestamp: string;
  timestampMs: number;
  tripSummary: TripSummary;
  status: 'ACTIVE' | 'IN_ACTIVE';
};

type fetchActiveTripsOutputType = {
  activeTrips: Array<TripData>;
};

export async function fetchActiveTrips() {
  const apiName = process.env.REACT_APP_API_GW_END_POINT_NAME as string;
  const path = '/v1/trip/active';
  const init = {
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      name: 'param', // OPTIONAL
    },
  };

  return await API.get(apiName, path, init)
    .then((response) => {
      return response.data as fetchActiveTripsOutputType;
    })
    .catch((error) => {
      return null;
    });
}
