import { CheckCircle } from '@phosphor-icons/react';
import React from 'react';
import { useTrip } from '../context/trip-context';
import { StudentType } from '../graphql/API';
import { manualEntry } from '../utils/Api-calls/manual-entry';
import { APP_STATUS } from '../constants/training.constants';
import { ImageComponent } from './full-match';

type ImageCardProps = {
  studentType: StudentType;
  isSelected: boolean;
  setSelectedValue: any;
};

export default function ImageCard({ studentType, isSelected, setSelectedValue }: ImageCardProps) {
  const trip = useTrip();

  const onConfirmClick = async () => {
    if (trip?.tripData?.tripId) {
      try {
        if (trip.matchData) {
          // Sending the api call to update the manual entry.
          trip.disAbleManualConfirmation.current = true;
          await manualEntry(
            trip?.tripData?.tripId,
            studentType.id,
            trip.matchData?.batchId,
            trip.matchData?.status,
          );
          await trip?.updateTripData(trip?.tripData?.tripId);
          // Resetting the app status to initial
          trip.appStatus.current = APP_STATUS.INITIAL;
          trip.disAbleManualConfirmation.current = false;
        }
      } catch (error) {
        return null;
      }
    }
  };
  return (
    <>
      <button
        disabled={trip?.disAbleManualConfirmation.current}
        onClick={() => {
          isSelected ? setSelectedValue('') : setSelectedValue(studentType.id);
        }}
        className={`relative rounded-full ${
          isSelected
            ? `border-4 border-emerald-500 border-spacing-0 disabled:border-emerald-500/20`
            : ``
        }`}
      >
        <ImageComponent url={studentType.image} />
        {isSelected && (
          <button
            disabled={trip?.disAbleManualConfirmation.current}
            onClick={(e) => {
              e.stopPropagation();
              onConfirmClick();
              setSelectedValue('');
            }}
            className='absolute bottom-[10%] right-[40%] flex items-center rounded-full bg-emerald-600 text-white p-1 xl:p-2 justify-center disabled:bg-emerald-600/20'
          >
            <CheckCircle size={25} weight='regular' className='' />
          </button>
        )}
      </button>
      <p className='text-slate-300 text-[16px] font-medium'>{studentType.studentName}</p>
    </>
  );
}
