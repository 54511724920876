import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { USER_DATA } from '../utils/localstorage.utils';

const AuthContext = createContext<{
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
} | null>(null);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<any | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | any>(
    localStorage.getItem('first-student-user') !== null,
  );

  const setAuthenticationStatus = async () => {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
    }
  };
  useEffect(() => {
    setAuthenticationStatus();
  }, []);

  const login = async (username: string, password: string) => {
    const user = await Auth.signIn({ username, password });
    setUser(user);
    setIsAuthenticated(true);
    return user;
  };

  const logout = async () => {
    await Auth.signOut();
    USER_DATA.remove();
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
