export const METRICS_CARD_MESSAGES = {
  TOTAL_STUDENTS: 'Total Number of Students for Pickups',
  PICKED_STUDENTS: 'Students Picked Up',
  NO_SHOWS: 'No Shows',
};

export enum TRIP_STATUS {
  ON_GOING = 'ACTIVE',
  ENDED = 'ENDED',
}

export enum APP_STATUS {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
}

export enum TRIP_STUDENT_STATUS {
  NOT_ONBOARDED = 'NOT_ONBOARDED',
  ONBOARDED = 'ONBOARDED',
  REQUIRE_MANUAL_CONFIRMATION = 'REQUIRE_MANUAL_CONFIRMATION',
}

export enum MATCH_TYPE {
  FULL_MATCH = 'FULL_MATCH',
  NO_MATCH = 'NO_MATCH',
  PARTIAL_MATCH = 'PARTIAL_MATCH',
}

export enum ENTITY_TYPES {
  STUDENT = 'STUDENT',
  BUS = 'BUS',
  TRIP = 'TRIP',
  ROUTE = 'ROUTE',
  TRIP_ENTRY = 'TRIP_ENTRY',
}

export const APP_CONSTANTS = {
  STANDARD_WAIT_TIME: 10000,
};

export enum BUTTON_MESSAGES {
  EXIT_BUTTON_TEXT = 'Exit',
  CONTINUE_WITH_TRIP = 'Continue With Trip',
  TOGGLE_BUTTON_SEAT_TEXT = 'Seats',
  TOGGLE_BUTTON_LIST_TEXT = 'List',
  LOADING = 'Loading',
}

export enum EMPTY_STATE_TEXT {
  EMPTY_STATE_TEXT = 'Ready for next SMILE',
}

export enum FULL_MATCH_CONSTANTS {
  AUTO_MATCHED = 'Auto matched to',
}

export enum PARTIAL_MATCH_CONSTANTS {
  MANUAL_INPUT_NEEDED = 'MANUAL INPUT NEEDED',
  NO_MATCH_TYPE = 'Who is this?',
  PARTIAL_MATCH_TYPE = 'Same or different?',
}

export enum CONNECTIVITY_ERROR_TEXT {
  CONNECTIVITY_ERROR = "Can't connect. Please check your internet connection.",
}
