import React from 'react';
import ApplicationRouter from './router/router';
import { Amplify } from 'aws-amplify';
import { AuthProvider } from './context/auth-context';
import { TripProvider } from './context/trip-context';
import { awsExports } from './aws-exports';

Amplify.configure(awsExports);

function App() {
  return (
    <AuthProvider>
      <TripProvider>
        <div className='application-container App w-full'>{<ApplicationRouter />}</div>
      </TripProvider>
    </AuthProvider>
  );
}

export default App;
