import React, { useEffect, useState } from 'react';
// eslint-disable-next-line react/prop-types,@typescript-eslint/ban-ts-comment
// @ts-ignore
import { ROUTES } from '../constants/routes.constants';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/auth-context';

// eslint-disable-next-line react/prop-types,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/prop-types
export function RequireAuth({ children }) {
  const auth = useAuth();

  console.log('IsAuthenticated: ', auth?.isAuthenticated);
  if (!auth || !auth.isAuthenticated) {
    return <Navigate to={ROUTES.login} replace />;
  }
  return children;
}

// eslint-disable-next-line react/prop-types,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/prop-types
export function ValidateAuth({ children }) {
  const auth = useAuth();
  return auth && auth.isAuthenticated ? <Navigate to={ROUTES.training} replace /> : children;
}
