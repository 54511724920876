import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { TripContextType, TripDataType } from '../types/trip.type';
import { APP_STATUS } from '../constants/training.constants';
import { fetchStudents } from '../utils/Api-calls/fetch-students';

const TripContext = createContext<TripContextType | null>(null);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TripProvider = ({ children }) => {
  const [tripData, setTripData] = useState<TripDataType | null>(null);
  const [matchStatus, setMatchStatus] = useState(null);
  const [matchData, setMatchData] = useState(null);
  const appStatus = useRef(APP_STATUS.INITIAL);
  const disAbleManualConfirmation = useRef(false);
  const updateTripData = async (tripId: any) => {
    // console.log('Updating the trip data: ', tripId);
    const data = await fetchStudents(tripId);
    // console.log(data);
    setTripData({
      tripId: data.trip.PK,
      tripStatus: data.trip.status,
      tripSummary: {
        totalStudentsBoardedTheTrip: data.trip.tripSummary.totalStudentsBoardedTheTrip,
        totalNoShows: data.trip.tripSummary.totalNoShows,
        totalStudentsInTheTrip: data.trip.tripSummary.totalStudentsInTheTrip,
      },
      tripEntries: data.entries,
    });
  };

  const reset = () => {
    setTripData(null);
    setMatchData(null);
    appStatus.current = APP_STATUS.INITIAL;
    disAbleManualConfirmation.current = false;
    setMatchStatus(null);
  };

  const continueWithTrip = () => {
    setMatchData(null);
    appStatus.current = APP_STATUS.INITIAL;
  };

  return (
    <TripContext.Provider
      value={{
        continueWithTrip,
        tripData,
        updateTripData,
        appStatus,
        disAbleManualConfirmation,
        matchStatus,
        setMatchStatus,
        matchData,
        setMatchData,
        reset,
      }}
    >
      {children}
    </TripContext.Provider>
  );
};

export const useTrip = () => {
  return useContext(TripContext);
};
