import { API } from 'aws-amplify';

export async function fetchTimeline(tripId: string) {
  const apiName = process.env.REACT_APP_API_GW_END_POINT_NAME as string;
  const path = '/v1/trip/timeline';

  return API.get(apiName, path, {
    queryStringParameters: {
      tripId,
    },
  });
}
