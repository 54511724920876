export const ROUTES = {
  login: '/login',
  home: '/home',
  dashboard: '/dashboard',
  training: '/training',
  students: '/students',
  settings: '/settings',
  all: '*',
  test: '/test',
};
