import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { FULL_MATCH_CONSTANTS } from '../constants/training.constants';
import { ContinueWithTrip } from './continue-with-trip';
import { isPreSignedUrl } from '../utils/common.utils';

interface FullMatchProps {
  capturedImageUrl: string;
  actualImageUrl: string;
  name: string;
  onBtnClk: () => void;
}

type FullMatchImageComponentProps = {
  url: string;
};

export function ImageComponent({ url }: FullMatchImageComponentProps) {
  return (
    <img
      // src={`data:image/png;base64,${capturedImageUrl}`}
      src={isPreSignedUrl(url) ? url : `data:image/png;base64,${url}`}
      className='object-cover xl:w-[220px] xl:h-[220px] w-[150px] h-[150px] inline-block xl:p-2 p-1 cursor-pointer ease-in-out duration-300 rounded-full'
    />
  );
}

export default function FullMatch({ capturedImageUrl, actualImageUrl, name }: FullMatchProps) {
  return (
    <div className='full-match-container w-full'>
      {/* text */}
      <div>
        <div className='flex items-center  justify-center flex-col text-white lg:text-[33px] font-bold text-center'>
          <p className='text-slate-300 lg:text-[26px] text-[18px] font-bold text-center'>
            {FULL_MATCH_CONSTANTS.AUTO_MATCHED}
          </p>
          <p className='text-white  lg:text-[33px] text-[23px] font-bold text-center truncate'>
            {name}
          </p>
        </div>
      </div>
      {/* image */}
      <div className='flex items-center gap-4 justify-center'>
        {/* left image */}
        <div>
          <ImageComponent url={capturedImageUrl} />
        </div>
        {/* Arrow */}
        <div className='text-slate-500'>
          <ArrowRight size={72} />
        </div>
        {/* right image */}
        <div>
          <ImageComponent url={actualImageUrl} />
        </div>
      </div>
      <ContinueWithTrip />
    </div>
  );
}

{
  /* 
Button component if needed in future.
<button onClick={onBtnClk}
className={'bg-white rounded-[3px] text-[8px] tablet:text-[14px] py-2 px-5 w-[70%] tablet:w-full'}>
Continuing with Trip in 10 seconds &rarr;
</button> */
}
